import { graphql } from 'gatsby';
import * as React from 'react';

import { HomePage } from '../components/HomePage/HomePage';
import { formatInfoLinks, formatMenuLinks } from '../utils/helpers';
import { IPageProps } from '../utils/types';

const Home = ({ data, location }: IPageProps): JSX.Element => {
  const {
    title,
    menuTitle,
    subHeading,
    featuredImage,
    headerType,
    description,
    sectionId,
    pageContent,
    meta,
    language,
  } = data?.contentfulPage;

  const menuLink = formatMenuLinks(data.allContentfulCategory.nodes, 'De');
  const infoLink = formatInfoLinks(data.allContentfulPrivacyAndToS.nodes, 'De');
  return (
    <HomePage
      menuLinks={menuLink}
      infoLinks={infoLink}
      title={title}
      headerType={headerType}
      menuTitle={menuTitle}
      featuredImage={featuredImage}
      subHeading={subHeading}
      staticLocation={location}
      description={description}
      sectionId={sectionId}
      pageContent={pageContent}
      meta={meta}
      language={language}
      vacationBanner={data.contentfulVacationBanner}
    />
  );
};

export const query = graphql`
  {
    allContentfulCategory(
      sort: { fields: order }
      filter: { language: { eq: "De" } }
    ) {
      nodes {
        ...category
      }
    }
    contentfulPage(slug: { eq: "home" }) {
      title
      language
      subHeading
      headerType
      description {
        description
      }
      menuTitle
      sectionId
      featuredImage {
        title
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: BLURRED
          aspectRatio: 1
          quality: 90
        )
      }
      description {
        description
      }
      pageContent {
        raw
        references {
          ...allBlocks
        }
      }
      meta {
        name
        content
      }
    }
    allContentfulPrivacyAndToS(filter: { language: { eq: "De" } }) {
      nodes {
        title
        language
        url: slug
      }
    }

    contentfulVacationBanner {
      ...vacationBanner
    }
  }
`;

export default Home;
